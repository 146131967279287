<clr-main-container *ngIf="user$ | async">
    <div>
        <clr-header class="bg-aquagroup">
            <div class="branding" style="width: 250px">
                <a href="#" [routerLink]="['/home']" [class.active]="router.url === '/home'">
                    <img width="36" height="36" class="clr-icon" src="assets/images/web_logo.png" />
                    <span class="title">{{ configuration.companyName }}</span>
                </a>
            </div>
            <div class="header-nav">
                <div class="nav-link" style="padding-top: 8px">
                    <button class="btn btn-primary" style="background-color: #42d1ec" (click)="visible = true">
                        Create
                    </button>
                </div>
            </div>
            <div class="header-actions">
                <pr-planning-item-search-modal class="search-center-alignment"></pr-planning-item-search-modal>

                <clr-dropdown class="dropdown bottom-right">
                    <button class="nav-text" clrDropdownToggle>
                        <cds-icon shape="user"></cds-icon>
                        {{ user.email }}
                    </button>
                    <div class="dropdown-menu">
                        <a
                            clrDropdownItem
                            href="#"
                            [routerLink]="['/preferences']"
                            [class.active]="router.url === '/preferences' || router.url === '/'"
                            >Preferences</a
                        >
                        <a
                            value=""
                            clrDropdownItem
                            href="#"
                            [routerLink]="['/userMaintenance']"
                            [class.active]="router.url === '/userMaintenance' || router.url === '/'"
                            >User Maintenance</a
                        >
                        <a *ngIf="configuration?.exact.loginEnabled" clrDropdownItem (click)="exactLogin()"
                            >Exact Login</a
                        >
                        <a clrDropdownItem (click)="logout()">Log out</a>
                    </div>
                </clr-dropdown>
            </div>
        </clr-header>
    </div>

    <div class="content-container">
        <clr-vertical-nav
            [clrVerticalNavCollapsible]="true"
            class="nav-trigger--bottom"
            [(clrVerticalNavCollapsed)]="collapsed"
            *ngIf="router.url !== '/login' && user$ | async"
        >
            <a
                clrVerticalNavLink
                class="nav-link"
                href="#"
                [routerLink]="['/home']"
                [class.active]="router.url === '/home'"
            >
                <cds-icon clrVerticalNavIcon shape="user"></cds-icon>
                Home
            </a>
            <a
                clrVerticalNavLink
                class="nav-link"
                href="#"
                [routerLink]="['/calendar-view']"
                [class.active]="router.url && router.url.indexOf('/calendar-view') > -1"
            >
                <cds-icon clrVerticalNavIcon shape="calendar"></cds-icon>
                Kalender
            </a>
            <a
                *ngIf="configuration?.planningItemTypes.includes(PlanningItemType.maintenance)"
                clrVerticalNavLink
                class="nav-link"
                href="#"
                [routerLink]="['/maintenance']"
                [class.active]="router.url && router.url.indexOf('/maintenance') > -1"
            >
                <cds-icon clrVerticalNavIcon shape="wrench"></cds-icon>
                Onderhoud
            </a>
            <a
                clrVerticalNavLink
                [routerLink]="['/leads']"
                routerLinkActive="active"
                *ngIf="configuration?.planningItemTypes.includes(PlanningItemType.lead)"
            >
                <cds-icon clrVerticalNavIcon shape="happy-face"></cds-icon>
                Leads
                <span class="badge badge-danger">{{ leadManager.newLeadCount$ | async }}</span>
            </a>
            <a
                *ngIf="configuration?.planningItemTypes.includes(PlanningItemType.repair)"
                clrVerticalNavLink
                [routerLink]="['/repair']"
                routerLinkActive="active"
            >
                <cds-icon clrVerticalNavIcon shape="warning-standard"></cds-icon>
                Depannage
            </a>
            <a
                *ngIf="configuration?.planningItemTypes.includes(PlanningItemType.delivery)"
                clrVerticalNavLink
                [routerLink]="['/deliveries']"
                routerLinkActive="active"
            >
                <cds-icon clrVerticalNavIcon shape="truck"></cds-icon>
                Leveringen
            </a>
            <a
                *ngIf="configuration?.planningItemTypes.includes(PlanningItemType.placement)"
                clrVerticalNavLink
                [routerLink]="['/placement']"
                routerLinkActive="active"
            >
                <cds-icon clrVerticalNavIcon shape="crosshairs"></cds-icon>
                Plaatsing
            </a>

            <clr-vertical-nav-group routerLinkActive="active" *ngIf="configuration?.quotes.enabled">
                <a clrVerticalNavLink [routerLink]="['/offer']" routerLinkActive="active">
                    <cds-icon clrVerticalNavIcon shape="bug"></cds-icon>
                    Offertes
                </a>
                <clr-vertical-nav-group-children>
                    <a clrVerticalNavLink [routerLink]="['/quote-stats-overview-page']" routerLinkActive="active">
                        Statistieken
                    </a>
                </clr-vertical-nav-group-children>
            </clr-vertical-nav-group>
            <a
                clrVerticalNavLink
                [routerLink]="['/work-order']"
                *ngIf="configuration.workOrders?.enabled"
                routerLinkActive="active"
            >
                <cds-icon clrVerticalNavIcon shape="form"></cds-icon>
                Werkbon
            </a>
            <a clrVerticalNavLink [routerLink]="['/product']" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="bundle"></cds-icon>
                Producten
            </a>
            <a clrVerticalNavLink [routerLink]="['/client']" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="user"></cds-icon>
                Klant
            </a>
            <a [routerLink]="['/activity']" clrVerticalNavLink routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="view-list"></cds-icon>
                Activiteit
            </a>
        </clr-vertical-nav>
        <main class="content-area">
            <router-outlet></router-outlet>
        </main>
    </div>
</clr-main-container>

<pr-create-modal [visible]="visible" (ok)="ok()" (cancel)="cancel()"></pr-create-modal>
