"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseIConfiguration = void 0;
const iconfiguration_exact_1 = require("./iconfiguration-exact");
const iconfiguration_mailing_1 = require("./iconfiguration-mailing");
const iconfiguration_planning_1 = require("./iconfiguration-planning");
const iconfiguration_quotes_1 = require("./iconfiguration-quotes");
const iconfiguration_water_stand_1 = require("./iconfiguration-water-stand");
const iconfiguration_work_order_1 = require("./iconfiguration-work-order");
function parseIConfiguration(data) {
    if (data.mailing !== null && typeof data.mailing === 'object')
        data.mailing = (0, iconfiguration_mailing_1.parseIConfigurationMailing)(data.mailing);
    if (data.workOrders !== null && typeof data.workOrders === 'object')
        data.workOrders = (0, iconfiguration_work_order_1.parseIConfigurationWorkOrder)(data.workOrders);
    if (data.waterStand !== null && typeof data.waterStand === 'object')
        data.waterStand = (0, iconfiguration_water_stand_1.parseIConfigurationWaterStand)(data.waterStand);
    if (data.planning !== null && typeof data.planning === 'object')
        data.planning = (0, iconfiguration_planning_1.parseIConfigurationPlanning)(data.planning);
    if (data.exact !== null && typeof data.exact === 'object')
        data.exact = (0, iconfiguration_exact_1.parseIConfigurationExact)(data.exact);
    if (data.quotes !== null && typeof data.quotes === 'object')
        data.quotes = (0, iconfiguration_quotes_1.parseIConfigurationQuotes)(data.quotes);
    return data;
}
exports.parseIConfiguration = parseIConfiguration;
