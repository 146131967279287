export enum QuoteWarranty {
    W1 = '5 jaar full omnium garantie zonder onderhoud. 5 Ans full omnium garanti sans maintenance',
    W2 = '10 jaar full omnium garantie met jaarlijks onderhoud. 10 Ans full omnium garanti avec maintenance annuel',
    W3 = '10 jaar full omnium garantie met 2-jaarlijks onderhoud. 10 Ans full omnium garanti avec maintenance tous les deux ans',
    W4 = '15 jaar full omnium garantie met jaarlijks onderhoud. 15 Ans full omnium garanti avec maintenance annuel',
    W5 = '15 jaar full omnium garantie met 2-jaarlijks onderhoud. 15 Ans full omnium garanti avec maintenance tous les deux ans',
}

export const QuoteWarrantyCode = {
    W1: '5J/ST',
    W2: '10J/1J-OND',
    W3: '10J/2J-OND',
    W4: '15J/1J-OND',
    W5: '15J/2J-OND',
};
