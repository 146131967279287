import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Location } from '@angular/common';
import * as toastr from 'toastr';
import { IProductEntity, PricingUnitType, ProductApi } from '@phrospr/api-backend';
import { getEnumKeys } from '@phrospr/lib-core';
import { ProductAttributes } from '@phrospr/lib-models';

@Component({
    selector: 'pr-upsert-product-page',
    styleUrls: ['./upsert-product-page.component.scss'],
    templateUrl: './upsert-product-page.component.html',
})
export class UpsertProductPageComponent implements OnInit {
    product: ProductAttributes;

    editMode = false;
    createMode = true;
    loadingSave = false;

    unitTypes = getEnumKeys(PricingUnitType);

    selectedUnitType = PricingUnitType.Stuk;

    constructor(private router: Router, private route: ActivatedRoute, private location: Location) {}

    ngOnInit(): void {
        this.route.queryParams.pipe(first()).subscribe(async params => {
            if (params.edit_mode === 'true') this.editMode = true;
        });
        this.loadProduct().catch(err => console.log(err));
    }

    goBack() {
        this.location.back();
    }

    async loadProduct() {
        this.route.queryParams.pipe(first()).subscribe(async params => {
            if (!params.product_id) {
                this.product = this.getNewProduct();
                this.editMode = true;
                return;
            }
            const loadedProduct = (await ProductApi.getProduct(parseInt('' + params.product_id, 10))).data;
            // I do this because i get back activities as well, but this is giving issues during the update with excess parameters
            this.product = {
                product_id: loadedProduct.product_id,
                product_number: loadedProduct.product_number,
                pricing: {
                    price_per_unit: loadedProduct.pricing.price_per_unit,
                    unit_amount: loadedProduct.pricing.unit_amount,
                    unit_type: loadedProduct.pricing.unit_type,
                },
                specifications: {
                    description: loadedProduct.specifications.description,
                    service: loadedProduct.specifications.service,
                },
                name: loadedProduct.name,
                creation_date: loadedProduct.creation_date,
                active: loadedProduct.active,
            };
            this.selectedUnitType = this.product.pricing.unit_type;
            this.createMode = false;
        });
    }

    getNewProduct(): ProductAttributes {
        return {
            product_id: undefined,
            product_number: null,
            pricing: {
                price_per_unit: null,
                unit_amount: null,
                unit_type: PricingUnitType.Stuk,
            },
            specifications: {
                description: null,
            },
            name: null,
            creation_date: new Date(),
            active: true,
        };
    }

    async addProduct() {
        try {
            if (
                !this.product.product_number ||
                !this.product.name ||
                (!this.product.pricing.price_per_unit && this.product.pricing.price_per_unit !== 0) ||
                !this.product.pricing.unit_amount
            ) {
                toastr.error('Je moet zeker product nummer, naam, eenheid & prijs invullen');
                return;
            }
            this.product.pricing.unit_type = this.selectedUnitType;
            await ProductApi.addProduct(this.product);
            await this.router.navigateByUrl(`/product`);
        } catch (e) {
            toastr.error('Error while trying to create product' + e);
        }
    }

    async updateProduct() {
        try {
            if (
                !this.product.product_number ||
                !this.product.name ||
                (!this.product.pricing.price_per_unit && this.product.pricing.price_per_unit !== 0) ||
                !this.product.pricing.unit_amount
            ) {
                toastr.error('Je moet zeker product nummer, naam, eenheid & prijs invullen');
                return;
            }
            const productUpdated = await ProductApi.updateProduct(this.product);
            this.editMode = false;
            this.loadProduct();
        } catch (e) {
            toastr.error('Error while trying to update product' + e);
        }
    }

    async cancelProductEdit() {
        await this.loadProduct();
        this.editMode = false;
    }
}
